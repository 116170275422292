import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { StaticVariable } from '../globle.class';
import { UserTransactionHistoryPojo } from '../Pojos/user-transaction-history.pojo';
import { TransactionTicketBookingPojo } from '../Pojos/transaction-ticket-booking.pojo';
import { DummyHundiTransactionPojo } from '../Pojos/dummy-Hundi-transaction.pojo';
import { PGRequestPojo } from '../Pojos/PG-Request.pojo';
import { DummyDonationTransactionPojo } from '../Pojos/dummy-donation-transaction.pojo';
import { DummyThirupaniDonationTransactionPojo } from '../Pojos/DummyThirupaniDonationTransaction.Pojo';
import { DummyRoomBookingTransactionPojo } from 'src/app/Pojos/dummy-room-booking-transaction.pojo';
import { IobTransactionRequestPojo } from '../Pojos/IobTransactionRequestPojo';
import { ResponsePojo } from '../Pojos/response.pojo';
import { DummyDCBTransactionPojo } from '../Pojos/dummy-DCB-transaction.pojo';
import { DcbTypePojo } from '../Pojos/dcb-type-pojo';


@Injectable({
    providedIn: 'root'
})

export class TransactionService {

    constructor(private httpClient: HttpClient) { }

    userTransactionHistorySearchByUserId(userId: number): Observable<UserTransactionHistoryPojo[]> {
        const url = StaticVariable.porturl + 'UserTransactionHistorySearchByUserId/' + userId;
        return this.httpClient.get<UserTransactionHistoryPojo[]>(url);
    }

    getReceiptByTransactionId(transactionId: number): Observable<TransactionTicketBookingPojo> {
        const url = StaticVariable.porturl + 'TicketBookingReceiptByTransactionId/' + transactionId;
        return this.httpClient.get<TransactionTicketBookingPojo>(url);
    }
    // addHundi( arg: DummyHundiTransactionPojo ): Observable<IobTransactionRequestPojo> {
    //     const url = StaticVariable.porturl + 'AddE_hundi';
    //     return this.httpClient.post<IobTransactionRequestPojo>( url, arg );
    // }

    addHundi( arg: DummyHundiTransactionPojo ): Observable<DummyHundiTransactionPojo> {
        const url = StaticVariable.porturl + 'AddE_hundi';
        return this.httpClient.post<DummyHundiTransactionPojo>( url, arg );
    }
    // addAnnadhanam(arg:DummyDonationTransactionPojo):Observable<IobTransactionRequestPojo>{
    //     const url = StaticVariable.porturl+'AddAnnathanam_Donation';
    //     return this.httpClient.post<IobTransactionRequestPojo>(url,arg);
    // }

    addAnnadhanam(arg:DummyDonationTransactionPojo):Observable<DummyDonationTransactionPojo>{
        const url = StaticVariable.porturl+'AddAnnathanam_Donation';
        return this.httpClient.post<DummyDonationTransactionPojo>(url,arg);
    }

    addThirupani(arg:DummyThirupaniDonationTransactionPojo):Observable<DummyThirupaniDonationTransactionPojo>{
        const url = StaticVariable.porturl+ 'addThirupaniDonation';
        return this.httpClient.post<DummyThirupaniDonationTransactionPojo>(url,arg);
    }
    checkRoomByDateandProofNo(date,proofno): Observable<any> {
        const url = StaticVariable.porturl + 'CheckRoomBookingByDateAndProofNo/'+date+'/'+proofno;
        return this.httpClient.get( url );
    }
    addRoomBooking(arg:DummyRoomBookingTransactionPojo):Observable<DummyRoomBookingTransactionPojo>{
        const url = StaticVariable.porturl +'AddRoomBooking';
        return this.httpClient.post<DummyRoomBookingTransactionPojo>(url,arg);
    }
    addGeneralDonation(arg:DummyDonationTransactionPojo):Observable<DummyDonationTransactionPojo>{
        const url = StaticVariable.porturl +'AddE_Donation';
        return this.httpClient.post<DummyDonationTransactionPojo>(url,arg);
    }
    getCurrentDate(): Observable<ResponsePojo>{
        const url = StaticVariable.porturl + 'GetCurrentDateResponse';
        return this.httpClient.get<ResponsePojo>(url);
    }
    addDcbCollection( arg: DummyDCBTransactionPojo ): Observable<DummyDCBTransactionPojo> {
        const url = StaticVariable.porturl + 'AddDcbCollection';
        return this.httpClient.post<DummyDCBTransactionPojo>( url, arg );
    }
    getDcbTypeAll(): Observable<DcbTypePojo[]>{
        const url=StaticVariable.porturl +'DcbTypeAll' ;
        return this.httpClient.get<DcbTypePojo[]>(url);
    }
}
