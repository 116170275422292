import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from '../globle.class';
import { DevoteeUserMasterPojo } from '../Pojos/devotee-user-master.pojo';
import { OtpMasterPojo } from '../Pojos/otp-master.pojo';
import { ResponsePojo } from '../Pojos/response.pojo';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})

export class DevoteeUserLoginService {

    constructor(private httpClient: HttpClient) { }

    userLogin(arg: DevoteeUserMasterPojo): Observable<DevoteeUserMasterPojo> {
        const url = StaticVariable.porturl + 'DevoteeLogin';
        return this.httpClient.post<DevoteeUserMasterPojo>(url, arg);
    }

    userLoginViaOtp(username: string, arg: OtpMasterPojo): Observable<DevoteeUserMasterPojo> {
        const url = StaticVariable.porturl + 'DevoteeOTPLoginVerify/' + username;
        return this.httpClient.post<DevoteeUserMasterPojo>(url, arg);
    }

    checkUsername(username: string): Observable<ResponsePojo> {
        const url = StaticVariable.porturl + 'CheckDevoteeUserName/' + username;
        return this.httpClient.get<ResponsePojo>(url);
    }

    passwordUpdate(arg: DevoteeUserMasterPojo): Observable<ResponsePojo> {
        const url = StaticVariable.porturl + 'DevoteePasswordUpdate';
        return this.httpClient.put<ResponsePojo>(url, arg);
    }

    otpLogin(username: string, otpMaster: OtpMasterPojo): Observable<ResponsePojo> {
        const url = StaticVariable.porturl + 'DevoteeOTPLogin/' + username;
        return this.httpClient.post<ResponsePojo>(url, otpMaster);
    }

}
