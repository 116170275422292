import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponsePojo } from '../Pojos/response.pojo';
import { StaticVariable } from '../globle.class';
import { OtpMasterPojo } from '../Pojos/otp-master.pojo';

@Injectable({
    providedIn: 'root'
})

export class OtpMasterService {

    constructor(private httpClient: HttpClient) { }

    generateOtp(arg: OtpMasterPojo): Observable<ResponsePojo> {
        const url = StaticVariable.porturl + 'GenerateOtp';
        return this.httpClient.post<ResponsePojo>(url, arg);
    }

    resendOtp(otpId: number): Observable<ResponsePojo> {
        const url = StaticVariable.porturl + 'ResendOTP/' + otpId;
        return this.httpClient.get<ResponsePojo>(url);
    }

    verifyOtp(arg: OtpMasterPojo): Observable<ResponsePojo> {
        const url = StaticVariable.porturl + 'VerifyOtpById';
        return this.httpClient.post<ResponsePojo>(url, arg);
    }

}
